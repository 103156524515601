import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";

function getStorageValue<DefaultValueType>(key: string, defaultValue: DefaultValueType): DefaultValueType {
  // get local stored value
  const saved = localStorage.getItem(key);
  if (saved) {
    const initial = JSON.parse(saved);
    return initial;
  }
  return defaultValue;
}

/**
 * Custom hook used to save state inside Local Storage or Query params
 * @param key Index key
 * @param defaultValue defaultValue
 * @returns 
 */
export const useCacheStorage = <DefaultValueType>(
  key: string,
  defaultValue: DefaultValueType
): [DefaultValueType, (value: DefaultValueType, save?: boolean) => void] => {
  const [searchParams] = useSearchParams();
  const [value, setValue] = useState(() => {
    const queryValue = searchParams.get(key);
    if (queryValue) {
      try {
        return JSON.parse(queryValue);
      } catch (error) {
        console.error(`Invalid query parameter: ${key} without value: ${queryValue}`);
      }
    }
    return getStorageValue<DefaultValueType>(key, defaultValue);
  });

  const saveValue = useCallback(
    (newValue: DefaultValueType, save = true) => {
      setValue(newValue);
      if (save) {
        if (newValue !== undefined) {
          localStorage.setItem(key, JSON.stringify(newValue));
          searchParams.set(key, JSON.stringify(newValue));
          // setSearchParams(searchParams, {
          //   replace: true,
          // });
        } else {
          localStorage.removeItem(key);
          searchParams.delete(key);
          // setSearchParams(searchParams, {
          //   replace: true,
          // });
        }
      }
    },
    [key, value]
  );

  return [value, saveValue];
};
