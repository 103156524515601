import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import clsx from "clsx";
import { observer } from "mobx-react";
import { ComponentPropsWithoutRef, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as AirportsArrowHorizontal } from "@app/assets/images/FlightsExplorer/cities-book-arrow.svg";
import { useCityById } from "@app/modules/Map/hooks/useCityById";
import { Flight } from "@app/modules/Map/models/Flight";
import { useFlightsExplorerService } from "@app/modules/Map/services/FlightsExplorerService";
import { formatMiles } from "@app/utils/number-format";

import "./FlightCard.css";

type Props = {
  flight: Flight;
};

export const FlightCard = observer((props: ComponentPropsWithoutRef<"button"> & Props) => {
  const { flight, ...rest } = props;
  const { t } = useTranslation(["landingpage"]);
  const { destinationCity, setDestinationCity, miles, originCity, cabin } = useFlightsExplorerService();

  const flightDestinationCity = useCityById(flight.destination.cityId);
  const isSelected = useMemo(() => flight.destination.cityId === destinationCity?.id, [destinationCity]);

  const sendDataToGTM = useGTMDispatch();

  const onLinkClick = useCallback(
    (choice: any) => {
      sendDataToGTM({
        event: "onCardClick",
        onCardClick: {
          choice,
          flight: {
            id: flight.id,
            originCity: flight.origin.cityId,
            destinationCity: flight.destination.cityId,
            miles: flight.entryMilesAmount,
            cabin: flight.cabin
          },
          filters: {
            miles,
            cabin,
            originCity: originCity?.code,
            destinationCity: destinationCity?.code
          }
        },
      });
    },
    [sendDataToGTM]
  );


  const onClick = useCallback(() => {
    if (!isSelected) {
      setDestinationCity(flightDestinationCity);
    }
  }, [flightDestinationCity, isSelected]);

  if (!flightDestinationCity) {
    return null;
  }

  const renderFlightTicket = (classNames: string) => (
    <div
      className={clsx(
        "absolute rounded-lg border border-gray top-0 bottom-0 left-0 right-0 py-[13px] pl-[28px] flex justify-between outline-none",
        classNames
      )}
    >
      <div className="absolute top-0 w-[30px] h-[15px] bg-white border border-gray right-[42px] -translate-y-[1px] rounded-b-[15px] border-t-0 box-border z-10"></div>
      <div className="absolute bottom-0 w-[30px] h-[15px] bg-white border border-gray right-[42px] translate-y-[1px] rounded-t-[15px] border-b-0 box-border z-10"></div>
      <div className="flex flex-col justify-between">
        <div className="flex flex-col">
          <p className="font-bold  text-[20px]">{t(`cities.${flight.destination.cityId}`, { ns: "locations" })}</p>
        </div>
        <div className="flex flex-col">
          <span className="mt-3 font-normal text-[11px]">{t(`map.fields.startingfrom`)}</span>
          <span className="font-light text-[28px]">{t(`map.fields.miles`, { miles: formatMiles(flight.entryMilesAmount) })}</span>
        </div>
      </div>

      <div className="relative w-[80px] justify-center items-center flex">
        <div className="absolute left-0 border-l border-dashed top-2 bottom-2 border-gray"></div>
        <div className="cursor-pointer rounded-full w-[40px] h-[40px] bg-blue flex items-center justify-center rotate-0">
          <AirportsArrowHorizontal className="text-white stroke-white"></AirportsArrowHorizontal>
        </div>
      </div>
    </div>
  );

  return (
    <button {...rest} onClick={() => onClick()} className={clsx("text-start group transition-colors delay-75 duration-300")}>
      <div className={clsx("relative rounded-lg h-36 min-w-[20rem] overflow-hidden", rest.className)}>
        {renderFlightTicket("text-blue bg-white")}
        {renderFlightTicket(clsx("text-white bg-blue animated-card", isSelected && "animated-card--active"))}
        {isSelected && (
          <div
            className={clsx(
              "absolute inset-0 flex justify-around py-4 transition-transform  bg-blue duration-200 text-white",
              "group-hover:translate-y-0 -translate-y-full"
            )}
          >
            <a
              href={`${t("map.fields.book.AF.link")}&cabinClass=${cabin.toUpperCase()}`}
              target="blank"
              className="px-2 tablet:py-[51px] text-center items-center flex font-bold"
              dangerouslySetInnerHTML={{ __html: t("map.fields.book.AF.text") }}
              onClick={() => onLinkClick("AF")}
            ></a>
            <div className="border-l border-dashed border-gray"></div>
            <a
              href={t(`${t("map.fields.book.KLM.link")}&cabinClass=${cabin.toUpperCase()}`)}
              target="blank"
              className="px-2 tablet:py-[51px] text-center items-center flex font-bold"
              dangerouslySetInnerHTML={{ __html: t("map.fields.book.KLM.text") }}
              onClick={() => onLinkClick("KLM")}
            ></a>
          </div>
        )}
      </div>
    </button>
  );
});
