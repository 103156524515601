import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import FlyingBlueLogo from "@app/assets/logo/flying-logo.svg";

export const ErrorBoundaryComponent = () => {
  const { t } = useTranslation(["landingpage"]);
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/");
    navigate(0);
  };

  return (
    <section className="flex flex-col w-screen h-screen">
      <div className="flex items-center w-full pl-8 phablet:pl-16 h-1/5 bg-blue">
        <img src={FlyingBlueLogo} alt="Flying Blue Logo" />
      </div>
      <div className="w-full p-8 phablet:p-16 h-4/5 bg-gray">
        <h2 className="text-3xl leading-normal phablet:leading-relaxed phablet:text-4xl text-blue">{t("error.message.title")}</h2>
        <p className="max-w-md my-5 text-base phablet:text-lg text-blue">{t("error.message.body")}</p>
        <button className="px-8 py-3 font-bold text-white bg-blue text-md rounded-3xl" onClick={onClick}>
          {t("error.message.cta")}
        </button>
      </div>
    </section>
  );
};
