import { sortBy, uniq } from "lodash";
import { useEffect, useRef, useState } from "react";

import { City } from "@app/modules/Map/models/City";
import { AABB } from "@app/modules/Map/utils/colliding";

const OVERLAP_HIDE_ATTRIBUTE = "data-hide";

const calculateOverlaps = (items: { cityId: string; element: HTMLDivElement }[]) => {
  const overlaps = items
    .map(({ cityId, element }) => {
      const cityOverlaps = element.hasAttribute(OVERLAP_HIDE_ATTRIBUTE)
        ? []
        : items
            .filter(({ cityId: otherCityId, element: otherElement }) => {
              if (otherCityId === cityId || otherElement.hasAttribute(OVERLAP_HIDE_ATTRIBUTE)) {
                return false;
              }
              return AABB.collide(element, otherElement);
            })
          .map(({ cityId: otherCityId }) => otherCityId);
      return {
        cityId,
        overlaps: cityOverlaps,
      };
    })
    .filter((city) => city.overlaps.length > 0);

  const overlapClusters = overlaps.reduce((clusters, overlap) => {
    const cluster = clusters.find((item) => {
      return item.includes(overlap.cityId);
    });
    if (cluster) {
      return clusters.filter((item) => item !== cluster).concat([uniq(cluster.concat(overlap.overlaps))]);
    } else {
      return clusters.concat([overlap.overlaps]);
    }
  }, [] as string[][]);

  return overlapClusters.map((cluster) =>
    cluster.map((city) => ({
      ...items.find((item) => item.cityId === city)!,
      overlaps: overlaps.find((item) => item.cityId === city)?.overlaps || [],
    }))
  );
};

type Props = {
  filteredCities: City[] | undefined;
  currentZoom: number;
  destinationCity: City | undefined;
  originCity: City | undefined;
};

export const useVisibleCities = (props: Props) => {
  const { filteredCities, currentZoom, destinationCity } = props;

  const itemsRef = useRef(new Map<string, HTMLDivElement>());

  const [visibleCities, setVisibleCities] = useState<string[]>([]);

  useEffect(() => {
    if (!filteredCities || filteredCities.length === 0) {
      return;
    }
    requestAnimationFrame(() => {
      const items = Array.from(itemsRef.current)
        .map(([cityId, element]) => ({
          cityId,
          element,
        }))
        .filter(({ element }) => element.getBoundingClientRect().width);

      let overlapClusters = calculateOverlaps(items);

      while (overlapClusters.length > 0) {
        for (const overlapCluster of overlapClusters) {
          const rankedOverlaps = sortBy(overlapCluster, [
            (overlap) => {
              return -overlap.overlaps.length;
            },
            (overlap) => {
              const city = filteredCities.find((city) => overlap.cityId === city.id);
              return city?.countryRank;
            },
            (overlap) => {
              const city = filteredCities.find((city) => overlap.cityId === city.id);
              return city?.zoneRank;
            },
          ]).filter((overlap) => destinationCity?.id !== overlap.cityId);
          if (rankedOverlaps.length > 0) {
            rankedOverlaps[0].element.setAttribute(OVERLAP_HIDE_ATTRIBUTE, "true");
          }
        }
        overlapClusters = calculateOverlaps(items);
      }

      const displayedCities: string[] = [];
      items.forEach(({ element, cityId }) => {
        const isHidden = element.hasAttribute(OVERLAP_HIDE_ATTRIBUTE);
        if (isHidden) {
          element.removeAttribute(OVERLAP_HIDE_ATTRIBUTE);
        } else {
          displayedCities.push(cityId);
        }
      });
      setVisibleCities(displayedCities);
    });
  }, [filteredCities, currentZoom]);

  const onRef = (city: City, el: HTMLDivElement | null) => {
    if (!el && itemsRef.current.has(city.id)) {
      itemsRef.current.delete(city.id);
    } else if (el) {
      itemsRef.current.set(city.id, el);
    }
  };

  return {
    visibleCities,
    itemsRef,
    onRef,
  };
};
