import "mapbox-gl/dist/mapbox-gl.css";
import { observer } from "mobx-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Map, { Layer, LineLayer, MapRef, Source, SymbolLayer } from "react-map-gl";

import { ReactComponent as DoubleArrow } from "@app/assets/icons/double-arrow.svg";
import { ReactComponent as LoaderIcon } from "@app/assets/icons/loader.svg";
import { useDebounce } from "@app/hooks/useDebounce";
import { CityMarkers } from "@app/modules/Map/components/FlightsMap/CityMarkers/CityMarkers";
import { PlaneIcon } from "@app/modules/Map/components/FlightsMap/PlaneIcon/PlaneIcon";
import { useAnimatedArc } from "@app/modules/Map/hooks/useAnimatedArc";
import { useFlightsExplorerService } from "@app/modules/Map/services/FlightsExplorerService";
import useCheckIsMobile from "@app/utils/useCheckIsMobile";

const lineLayerStyle = {
  id: "lines",
  type: "line",
  layout: {
    "line-cap": "round",
    "line-join": "round",
  },
  paint: {
    "line-color": "#0800B9",
    "line-width": 2,
    "line-dasharray": [0, 2, 2],
  },
} as LineLayer;

const iconLayerStyle = {
  id: "icon",
  type: "symbol",
  layout: {
    "icon-image": "plane-icon",
    "icon-size": 0.7,
    "icon-rotate": ["get", "bearing"],
    "icon-rotation-alignment": "map",
    "icon-allow-overlap": true,
    "icon-ignore-placement": true,
  },
} as SymbolLayer;

const INITIAL_STATE = {
  latitude: 46.53682836171123,
  longitude: 0.5713846158124625,
  zoom: 3.7773100108595554,
};

type Props = {
  onFullscreen?: () => void;
};

export const FlightsMap = observer((props: Props) => {
  const { onFullscreen } = props;
  const { t } = useTranslation(["landingpage"]);
  const { originCity, destinationCity, isFlightsLoading } = useFlightsExplorerService();
  const [isMapLoading, setMapLoading] = useState(true);

  const map = useRef<MapRef | null>(null);
  const [currentZoom, setCurrentZoom] = useState(INITIAL_STATE.zoom);
  const [debouncedZoom] = useDebounce([currentZoom], 300);

  const fitMapToCities = useCallback(() => {
    if (originCity && destinationCity) {
      map.current?.fitBounds(
        [
          {
            lon: originCity.location.longitude,
            lat: originCity.location.latitude,
          },
          {
            lon: destinationCity.location.longitude,
            lat: destinationCity.location.latitude,
          },
        ],
        { padding: 70, maxZoom: 5.5 }
      );
    }
  }, [originCity, destinationCity]);

  useEffect(() => {
    fitMapToCities();
  }, [fitMapToCities]);

  const onLoad = useCallback(() => {
    setMapLoading(false);
    fitMapToCities();
  }, []);

  const { lineData, iconData } = useAnimatedArc(...useDebounce([originCity, destinationCity], 500));

  return (
    <div className="relative flex flex-1 h-full">
      {(isMapLoading || isFlightsLoading) && (
        <div className="absolute inset-0 z-30 flex items-center justify-center bg-black bg-opacity-10">
          <LoaderIcon className="w-16 h-16 animate-spin text-blue" />
        </div>
      )}
      {useCheckIsMobile() && onFullscreen && (
        <button className="absolute inset-0 z-30 flex items-center justify-center" onClick={onFullscreen}>
          <div className="absolute z-30 flex items-center justify-center w-10 h-10 bg-white border rounded cursor-pointer bottom-2 right-2 border-gray">
            <DoubleArrow width="1.4rem" height="1.4rem" className="-rotate-45" />
          </div>
        </button>
      )}
      <Map
        ref={map}
        onLoad={onLoad}
        onResize={fitMapToCities}
        initialViewState={INITIAL_STATE}
        style={{ flex: 1, maxHeight: "100%" }}
        pitchWithRotate={false}
        dragRotate={false}
        touchZoomRotate={true}
        touchPitch={false}
        minPitch={0}
        maxPitch={0}
        minZoom={1}
        onZoomEnd={(e) => setCurrentZoom(e.viewState.zoom)}
        mapStyle={t("map.link")}
      >
        <PlaneIcon></PlaneIcon>
        <Source id="line-data" type="geojson" data={lineData}>
          <Layer {...lineLayerStyle} />
        </Source>
        <Source id="icon-data" type="geojson" data={iconData}>
          <Layer {...iconLayerStyle} />
        </Source>
        <CityMarkers currentZoom={debouncedZoom}></CityMarkers>
      </Map>
    </div>
  );
});
