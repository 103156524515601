import clsx from "clsx";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { useFlightsExplorerService } from "@app/modules/Map/services/FlightsExplorerService";

export const MilesSelector = observer(() => {
  const { t } = useTranslation(["landingpage"]);
  const { miles, setMiles } = useFlightsExplorerService();

  return (
    <div className="relative flex mx-auto w-72">
      <input
        className="py-[22px] px-[22px] placeholder:text-center placeholder:font-normal w-full font-bold text-left text-blue bg-white border border-gray rounded-md focus:border-blue focus:outline-none focus:ring-1 focus:ring-blue phablet:text-sm"
        value={miles === undefined ? "" : miles}
        type="number"
        placeholder={t("map.fields.set.miles")}
        onChange={(e) => setMiles(e.target.value ? parseInt(e.target.value) : undefined)}
        min="1"
        max="1000000"
        name="Miles number"
      />
      <span
        onClick={() => setMiles(undefined)}
        title="Clear Miles"
        className={clsx(
          miles && miles > 0 ? "flex" : "hidden",
          "absolute font-bold top-0 bottom-0 h-6 my-auto cursor-pointer right-4 text-blue"
        )}
      >
        x
      </span>
    </div>
  );
});
