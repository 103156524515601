import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { Disclosure, Transition } from "@headlessui/react";
import clsx from "clsx";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as ArrowDownIcon } from "@app/assets/icons/arrow-down.svg";
import translations from "@app/assets/translations.json";
import { i18n } from "@app/utils/i18n";

export const Faq = () => {
  const { t } = useTranslation(["landingpage"]);

  const sendDataToGTM = useGTMDispatch();

  const onQuestionClick = useCallback(
    (question: any) => {
      sendDataToGTM({
        event: "onQuestionClick",
        onQuestionClick: question,
      });
    },
    [sendDataToGTM]
  );

  const faqQuestions = useMemo(() => (translations as any)[i18n.resolvedLanguage].landingpage.faq.questions, [i18n.resolvedLanguage]);

  return (
    <div className="phablet:px-6 laptop:px-8 laptop:mb-24 max-w-[80rem] mx-auto">
      <h6 className="mx-6 text-4xl font-bold phablet:ml-0 tablet:text-4xl laptop:text-6xl text-blue desktop:text-7xl tablet:mb-20">
        {t("faq.title")}
      </h6>
      <div className="flex flex-col items-start content-center mt-8 tablet:mt-12 laptop:mt-14 desktop:mt-16">
        {faqQuestions.map((faqQuestion: any, index: number) => (
          <Disclosure key={faqQuestion.question}>
            {({ open }) => (
              <>
                <Disclosure.Button
                  onClick={() => onQuestionClick({ ...faqQuestion, id: index, isOpen: open })}
                  className={clsx(
                    open ? "bg-cyan-light" : "bg-white",
                    "border-t border-gray py-8 px-6 text-xl text-left tablet:text-2xl font-bold text-blue w-full flex flex-row items-center justify-between"
                  )}
                >
                  <p className="max-w-[90%]">{faqQuestion.question}</p>
                  <ArrowDownIcon className={clsx(open ? "rotate-180" : "rotate-0", "w-[25px] h-[25px] ")} />
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition duration-100 ease-out"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition ease-in ease-out duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Disclosure.Panel className="w-full px-6 py-8 text-base leading-6 laptop:w-4/5 laptop:pr-auto text-blue">
                    {faqQuestion.answer}
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  );
};
