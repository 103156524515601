import { Listbox, Transition } from "@headlessui/react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as ArrowSolidDownIcon } from "@app/assets/icons/arrow-solid-down.svg";

type Props = {
  value: "asc" | "desc";
  onChange: (value: "asc" | "desc") => void;
};

const options = [
  {
    label: "map.fields.lowest",
    value: "asc",
  },
  {
    label: "map.fields.highest",
    value: "desc",
  },
];

export const OrderSelector = observer((props: Props) => {
  const { value, onChange } = props;
  const { t } = useTranslation(["landingpage"]);

  const mappedValue = options.find((option) => option.value === value)!;

  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <>
          <Listbox.Label className="hidden text-sm font-medium text-gray-700">Class</Listbox.Label>
          <div className="relative w-auto">
            <Listbox.Button
              className={clsx(
                "relative w-full h-full py-2 text-left bg-white border-gray cursor-pointer focus:outline-none phablet:text-sm rounded-t-md pr-[30px]"
              )}
            >
              <div className="absolute right-[15px] z-10 top-0 bottom-0 flex items-center text-blue">
                <ArrowSolidDownIcon />
              </div>
              <span className="block truncate text-blue text-[10px] tablet:text-[12px]">{t(mappedValue.label)}</span>
            </Listbox.Button>
            <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options className="top-0 absolute divide-y-[1px] divide-gray divabsolute z-20 w-full overflow-auto text-[10px] tablet:text-[12px] bg-white rounded-md max-h-60 border border-blue focus:outline-none">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }) =>
                      clsx(active ? "text-white bg-blue" : "text-gray-900", "text-blue cursor-default select-none relative py-2 px-2")
                    }
                    value={option.value}
                  >
                    <span className={clsx("block truncate")}>{t(option.label)}</span>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
});
