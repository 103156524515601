import { useTranslation } from "react-i18next";

import FlyingBlueLogo from "@app/assets/logo/flying-logo.svg";
import { LanguageSelector } from "@app/components/LanguageSelector/LanguageSelector";

import { ReadMoreIcon } from "./ReadMoreIcon";

export const Hero = () => {
  const { t } = useTranslation(["landingpage"]);

  return (
    <section className="flex flex-col-reverse min-h-[50vh] phablet:[80vh] tablet:flex-row tablet:h-[65vh] tablet:min-h-[30em]">
      <div className="hidden phablet:w-full phablet:h-1/2 phablet:flex tablet:w-1/2 tablet:h-full desktop:w-7/12 bg-blue">
        <video
          className="object-cover w-full min-h-full bg-blue animate-ease-in"
          loop
          autoPlay
          playsInline
          muted
          src={require(`../../assets/video/video-hero.mp4`)}
        />
      </div>
      <div className="relative flex flex-col justify-between w-full h-full p-8 pb-48 text-white bg-blue phablet:p-10 phablet:h-1/2 tablet:h-full tablet:w-1/2 tablet:pb-32 tablet:pl-16 desktop:w-5/12 desktop:px-20">
        <div className="flex flex-row justify-between w-full">
          <a href="https://www.flyingblue.fr" target="blank" className="">
            <img src={FlyingBlueLogo} alt="Flying Blue Logo" className="desktop:w-[197px] w-[144px]" />
            {/* <img src="/logo192.png" alt="Flying Blue Logo" /> */}
          </a>
          <LanguageSelector />
        </div>
        <div className="mt-10 max-w-[300px] phablet:max-w-[400px] desktop:max-w-[550px]">
          <h1 className="mb-5 text-5xl font-black break-words laptop:text-5xl desktop:w-[80%]">{t("header.title")}</h1>
          <h2 className="w-2/3 text-base font-normal tablet:w-8/12 desktop:w-6/12">{t("header.subTitle")}</h2>
        </div>
        <div className="absolute left-0 right-0 mx-auto bottom-8 phablet:-bottom-16 ltr:phablet:right-0 rtl:phablet:left-0 phablet:mx-0 ltr:phablet:left-auto rtl:phablet:right-auto tablet:bottom-10 ltr:tablet:-left-16 rtl:tablet:-right-16">
          <ReadMoreIcon />
        </div>
      </div>
    </section>
  );
};
