/* eslint-disable react/display-name */
import clsx from "clsx";
import { observer } from "mobx-react";
import { ForwardedRef, forwardRef, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Marker } from "react-map-gl";

import { useEntryMilesAmount } from "@app/modules/Map/hooks/useEntryMilesAmount";
import { City } from "@app/modules/Map/models/City";
import { useFlightsExplorerService } from "@app/modules/Map/services/FlightsExplorerService";
import { formatMiles } from "@app/utils/number-format";

type Props = {
  city: City;
  scale: number;
  isVisible: boolean;
  onClick: () => void;
};

const CityMarkerForwardRef = forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => {
  const { t } = useTranslation(["landingpage"]);
  const { originCity, destinationCity, cabin } = useFlightsExplorerService();
  const { onClick, city, scale, isVisible } = props;

  const [isHover, setIsHover] = useState(false);

  const entryMilesAmount = useEntryMilesAmount(city, originCity!, cabin);

  const zIndex = useMemo(() => {
    if (isHover) {
      return 20;
    }
    if (originCity?.id === city.id || destinationCity?.id === city.id) {
      return 10;
    }
    if (isVisible) {
      return 5;
    }
    return 1;
  }, [originCity, destinationCity, city, isHover, isVisible]);

  return (
    <Marker key={city.id} longitude={city.location.longitude} latitude={city.location.latitude} anchor="center" style={{ zIndex }}>
      <button
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={onClick}
        className={clsx(
          "z-10 top-0 flex items-center justify-center rounded-full relative group",
          city.id === originCity?.id || city.id === destinationCity?.id ? "bg-blue w-4 h-4" : "bg-white w-3 h-3 hover:bg-blue"
        )}
        style={{ transformOrigin: "center", transform: `scale(${scale}%)` }}
      >
        <div
          ref={ref}
          className={clsx(
            "absolute z-20 -top-1 -translate-y-full flex-col p-2 mb-3 bg-white rounded border group-hover:border-blue flex group-hover:visible transition-opacity group-hover:opacity-100 duration-200 ease-in-out",
            isVisible ? "visible opacity-100" : "invisible opacity-0",
            originCity?.id === city.id || destinationCity?.id === city.id ? "border-blue" : "border-[#dbdbdb]"
          )}
          onClick={onClick}
        >
          <span className="whitespace-nowrap">{t(`cities.${city.code}`, { ns: "locations" })}</span>
          {entryMilesAmount > 0 && (
            <span className="font-bold whitespace-nowrap">{t(`map.fields.miles`, { miles: formatMiles(entryMilesAmount) })}</span>
          )}
        </div>
        <span
          className={clsx(
            "rounded-full",
            city.id === originCity?.id || city.id === destinationCity?.id ? "bg-white w-3 h-3" : "bg-blue w-2 h-2 group-hover:bg-white"
          )}
        ></span>
      </button>
    </Marker>
  );
});

export const CityMarker = observer(CityMarkerForwardRef);
