import { Listbox, Transition } from "@headlessui/react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as ArrowSolidDownIcon } from "@app/assets/icons/arrow-solid-down.svg";
import SeatIcon from "@app/assets/icons/seat.webp";
import { FlightClass } from "@app/modules/Map/models/Flight";

type Props = {
  value: FlightClass;
  onChange: (value: FlightClass) => void;
};

const options = [
  {
    label: "map.fields.class1",
    value: FlightClass.BUSINESS,
  },
  {
    label: "map.fields.class2",
    value: FlightClass.PREMIUM_ECONOMY,
  },
  {
    label: "map.fields.class3",
    value: FlightClass.ECONOMY,
  },
];

export const CabinSelector = observer((props: Props) => {
  const { value, onChange } = props;
  const { t } = useTranslation(["landingpage"]);

  const mappedValue = options.find((option) => option.value === value)!;

  return (
    <div className="flex items-center w-full">
      <img src={SeatIcon} className="mr-[14px]"></img>
      <Listbox value={value} onChange={onChange}>
        {({ open }) => (
          <>
            <Listbox.Label className="hidden text-sm font-medium text-gray-700">Class</Listbox.Label>
            <div className="relative w-full">
              <Listbox.Button
                className={clsx(
                  "relative w-full py-2 text-left bg-white border-gray cursor-pointer focus:outline-none phablet:text-sm border-b"
                )}
              >
                <div className="absolute right-[15px] z-10 top-0 bottom-0 flex items-center text-blue">
                  <ArrowSolidDownIcon />
                </div>
                <span className="block truncate text-blue font-bold text-[15px]">{t(mappedValue.label)}</span>
              </Listbox.Button>
              <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                <Listbox.Options className="top-0 absolute divide-y-[1px] divide-gray divabsolute z-10 w-full overflow-auto text-base bg-white rounded-md max-h-60 border-2 border-blue focus:outline-none phablet:text-sm">
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.value}
                      className={({ active, selected }) =>
                        clsx(
                          active ? "text-white bg-blue" : "text-gray-900",
                          selected ? "font-black" : "font-bold",
                          "text-blue cursor-default select-none relative py-2 pl-3 pr-9"
                        )
                      }
                      value={option.value}
                    >
                      <span className={clsx("block truncate")}>{t(option.label)}</span>
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
});
