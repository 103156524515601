import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";

import "./AnimatedLink.css";

type Props = {
  text: string;
  idleClassName?: string;
  hoverClassName?: string;
};

export const AnimatedLink = (props: ComponentPropsWithoutRef<"a"> & Props) => {
  const { text, className, idleClassName, hoverClassName, ...rest } = props;

  return (
    <a className={clsx("relative box-border overflow-hidden outline-none", className)} {...rest}>
      <span>{text}</span>
      <div className={clsx("flex items-center absolute inset-0 justify-center", className, idleClassName)}>
        <span>{text}</span>
      </div>
      {/* Fix for border radius pixels antialising */}
      <div className={clsx("absolute -inset-1 animated-button", className, hoverClassName)} />
      <div className={clsx("flex items-center absolute inset-0 justify-center animated-button", className, hoverClassName)}>
        <span>{text}</span>
      </div>
    </a>
  );
};
