import { useCallback, useState } from "react";

function getStorageValue<DefaultValueType>(key: string, defaultValue: DefaultValueType): DefaultValueType {
  // get local stored value
  const saved = localStorage.getItem(key);
  if (saved) {
    const initial = JSON.parse(saved);
    return initial;
  }
  return defaultValue;
}

/**
 * Custom hook used to save state inside Local Storage
 * @param key Index key
 * @param defaultValue defaultValue
 * @returns
 */
export const useLocalStorage = <DefaultValueType>(
  key: string,
  defaultValue: DefaultValueType
): [DefaultValueType, (value: DefaultValueType) => void] => {
  const [value, setValue] = useState(() => {
    return getStorageValue<DefaultValueType>(key, defaultValue);
  });

  const saveValue = useCallback(
    (newValue: DefaultValueType) => {
      setValue(newValue);
      if (newValue !== undefined) {
        localStorage.setItem(key, JSON.stringify(newValue));
      } else {
        localStorage.removeItem(key);
      }
    },
    [key, value]
  );

  return [value, saveValue];
};
