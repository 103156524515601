import clsx from "clsx";
import { orderBy, uniqBy } from "lodash";
import { observer } from "mobx-react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { AnimatedButton } from "@app/modules/Animations/components/AnimatedButton/AnimatedButton";
import { FlightCard } from "@app/modules/Map/components/FlightCard/FlightCard";
import { OrderSelector } from "@app/modules/Map/components/OrderSelector/OrderSelector";
import { useFlightsExplorerService } from "@app/modules/Map/services/FlightsExplorerService";
import useCheckIsMobile from "@app/utils/useCheckIsMobile";

type Props = {
  horizontal?: boolean;
};

export const FlightsList = observer((props: Props) => {
  const { horizontal = false } = props;
  const { t, i18n } = useTranslation(["landingpage"]);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const defaultNumberTickets = useCheckIsMobile() ? 3 : 5;
  const [numberOfTickets, setNumberOfTickets] = useState<number>(defaultNumberTickets);

  const { flights, originCity, destinationCity } = useFlightsExplorerService();

  const sortedFlights = useMemo(() => {
    if (!flights) {
      return [];
    }
    const visibleFlights = destinationCity ? flights.filter((flight) => flight.destination.cityId === destinationCity.id) : [...flights];
    return uniqBy(
      orderBy(
        visibleFlights,
        [
          (flight) => (["PAR", "AMS"].includes(flight.destination.cityId) ? 1 : 0),
          (flight) => flight.entryMilesAmount,
          (flight) => t(`cities.${flight.destination.cityId}`),
        ],
        ["desc", order, "asc"]
      ),
      (flight) => flight.destination.cityId
    );
  }, [flights, order, destinationCity, i18n.resolvedLanguage]);

  return (
    <div className="flex flex-col overflow-hidden">
      <div className={clsx("flex", horizontal ? "justify-between px-6" : "justify-between")}>
        <p className="text-blue font-bold text-[24px]">
          {t("map.fields.travel", { city: originCity ? t(`cities.${originCity.code}`, { ns: "locations" }) : "..." })}
        </p>
        <OrderSelector value={order} onChange={setOrder} />
      </div>
      {/* Result list */}
      <div
        className={clsx(
          "flex gap-3 mt-5 tablet:gap-5 tablet:pr-[16px]",
          horizontal ? "flex-row overflow-x-auto pb-3 px-6" : "flex-col overflow-y-auto"
        )}
      >
        {sortedFlights && sortedFlights.length > 0 ? (
          <>
            {sortedFlights.slice(0, numberOfTickets).map((flight) => (
              <FlightCard key={`${flight.id}`} flight={flight}></FlightCard>
            ))}
            {sortedFlights.length > numberOfTickets && (
              <div className="flex items-center justify-center">
                <AnimatedButton
                  onClick={() => setNumberOfTickets(numberOfTickets + defaultNumberTickets)}
                  text={t("map.fields.more.flights")}
                  className="px-8 py-3 font-bold rounded-full"
                  idleClassName="text-blue bg-white ring-1 ring-gray ring-inset"
                  hoverClassName="bg-blue text-white"
                ></AnimatedButton>
              </div>
            )}
          </>
        ) : (
          <p className="text-blue font-bold text-[18px] my-5">{t("error.message.map.body")}</p>
        )}
      </div>
    </div>
  );
});
