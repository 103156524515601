import { useTranslation } from "react-i18next";

import { Faq } from "@app/components/Faq/Faq";
import { Footer } from "@app/components/Footer/Footer";
import { Hero } from "@app/components/Hero/Hero";
import { LearnMore } from "@app/components/LearnMore/LearnMore";
import { FlightsNavigator } from "@app/modules/Map/components/FlightsNavigator";

export const HomeView = () => {
  const { t } = useTranslation(["landingpage"]);
  return (
    <div>
      <Hero />
      <div className="max-w-4xl px-5 mx-auto mt-6 overflow-hidden tablet:mt-8 laptop:mt-12 desktop:mt-14 phablet:px-6 laptop:px-8" id="map-section">
        <div className="flex flex-col justify-center">
          <h3 className="text-4xl font-extrabold text-center text-blue">{t("body.title")}</h3>
          <p className="mt-4 text-[18px] font-medium text-center text-blue">{t("body.subtitle")}</p>
        </div>
      </div>
      <div className="mt-6 tablet:mt-8 laptop:mt-12 desktop:mt-14">
        <FlightsNavigator />
      </div>
      <div className="mt-6 tablet:mt-8 laptop:mt-12 desktop:mt-14">
        <LearnMore />
      </div>
      <div className="mt-6 tablet:mt-8 laptop:mt-12 desktop:mt-14">
        <Faq />
      </div>
      <div className="mt-6 border-t border-gray tablet:mt-8 ">
        <Footer />
      </div>
    </div>
  );
};
