import { createContext, PropsWithChildren, useContext, useMemo, useState } from "react";

import { useLocalStorage } from "@app/hooks/useLocalStorage";

export interface CookiesService {
  isModalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  cookiesConsentAnswered: boolean;
  setCookiesConsentAnswered: (value: boolean) => void;
}

export const CookiesContext = createContext<CookiesService>(null as never);

export const CookiesServiceProvider = (props: PropsWithChildren) => {
  const [cookiesConsentAnswered, setCookiesConsentAnswered] = useLocalStorage("cookies-consent-answered", false);

  const [isModalOpen, setModalOpen] = useState<boolean>(() => {
    return !cookiesConsentAnswered;
  });

  const cookiesService = useMemo(
    () => ({
      setCookiesConsentAnswered,
      cookiesConsentAnswered,
      isModalOpen,
      setModalOpen,
    }),
    [isModalOpen, setModalOpen, cookiesConsentAnswered]
  );

  return <CookiesContext.Provider value={cookiesService}>{props.children}</CookiesContext.Provider>;
};

export const useCookiesService = () => useContext(CookiesContext);
