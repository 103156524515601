import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { Listbox, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Fragment, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as ArrowSolidDownIcon } from "@app/assets/icons/arrow-solid-down-white.svg";
import { useLocalStorage } from "@app/hooks/useLocalStorage";

export const LanguageSelector = () => {
  const sendDataToGTM = useGTMDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [, setSaveLanguage] = useLocalStorage<string | undefined>("language", undefined);

  const { i18n, t } = useTranslation(["landingpage"]);

  const onLanguageChange = useCallback(
    (newLanguage: string) => {
      setSaveLanguage(newLanguage);
      sendDataToGTM({
        event: "onLanguageChange",
        onLanguageChange: newLanguage,
      });
      navigate({
        pathname: location.pathname.replace(`/${i18n.resolvedLanguage}`, `/${newLanguage}`),
        hash: location.hash,
        search: location.search,
      });
    },
    [i18n, i18n.languages, sendDataToGTM]
  );

  return (
    <div className="relative">
      <Listbox value={i18n.resolvedLanguage} onChange={onLanguageChange}>
        {({ open }) => (
          <>
            <Listbox.Button className="flex flex-row items-center py-2 text-xs text-right text-white cursor-pointer phablet:text-right focus:outline-none phablet:text-sm">
              <span>{t("header.language")?.toUpperCase()}</span>
              <ArrowSolidDownIcon className="w-2 h-2 ml-2" />
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              enter="transition duration-200 ease-out"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in ease-out duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="top-0 rtl:left-0 ltr:right-0 absolute divide-y-[1px] divide-blue divabsolute overflow-y-scroll text-base bg-white text-blue max-h-72 focus:outline-none phablet:text-sm z-50">
                {Object.keys(i18n.options.resources!).map((language) => {
                  return (
                    <Listbox.Option
                      key={language}
                      value={language}
                      className={({ selected }) => clsx(selected ? "font-bold " : "font-normal", "px-5 py-3 cursor-pointer w-52")}
                    >
                      <span className="text-xs">{i18n.t("name", { lng: language, ns: "common" }).toString().toUpperCase()}</span>
                    </Listbox.Option>
                  );
                })}
              </Listbox.Options>
            </Transition>
          </>
        )}
      </Listbox>
    </div>
  );
};
