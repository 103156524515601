import { useEffect, useState } from "react";

/**
 * Custom hook that returns a boolean based on screen size if its a mobile sized screen
 * 
 * @returns current window's width & checks against a 768px base mobile screen
 */
const useCheckIsMobile = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return width <= 768;
};

export default useCheckIsMobile;
