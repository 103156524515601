import { useMap } from "react-map-gl";

import PlaneIconImage from "@app/assets/images/FlightsExplorer/plane.png";

export const PlaneIcon = () => {
  const { current: map } = useMap();

  if (map) {
    if (!map.hasImage("plane-icon")) {
      map.loadImage(PlaneIconImage, (error, image) => {
        if (error) throw error;
        if (!map.hasImage("plane-icon")) {
          map.addImage("plane-icon", image as any);
        }
      });
    }
  }

  return null;
};
