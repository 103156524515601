import { flow, makeAutoObservable } from "mobx";

import { Flight } from "@app/modules/Map/models/Flight";

interface CityLocation {
  longitude: number;
  latitude: number;
}

export class City {
  id!: string;
  code!: string;
  country!: string;
  zone!: string;
  zoneRank!: number;
  countryRank!: number;
  entryMilesAmount!: number;
  location!: CityLocation;
  flights?: Flight[];

  static createCity(data: City): City {
    const flight = new City();
    Object.assign(flight, data);
    return flight;
  }

  private constructor() {
    makeAutoObservable(this, {
      fetchFlights: flow,
    });
  }

  *fetchFlights() {
    if (this.flights) {
      return this.flights;
    }
    const flightsData: any[] = yield fetch(`/api/cities/${this.id}/flights.json`).then((result) => result.json());
    this.flights = flightsData.map((flightData) => Flight.createFlight(this, flightData));
    return this.flights;
  }
}
