import * as turf from "@turf/turf";
import { useEffect, useMemo, useState } from "react";

import { City } from "@app/modules/Map/models/City";
function calculateCurve(startPoint: number[], endPoint: number[]) {
  const p1 = turf.point(startPoint);
  const p2 = turf.point(endPoint);
  return turf.greatCircle(p1, p2);
}

export function useAnimatedArc(originCity?: City, destinationCity?: City): any {
  const data = useMemo(() => {
    if (!originCity || !destinationCity) {
      return;
    }
    const originPoint = [originCity.location.longitude, originCity.location.latitude];
    const destinationPoint = [destinationCity.location.longitude, destinationCity.location.latitude];
    const arc = calculateCurve(originPoint, destinationPoint);

    return arc;
  }, [originCity, destinationCity]);

  const [lineData, setLineData] = useState<number[][] | null>(null);
  const [iconData, setIconData] = useState<{ bearing: number; coordinates: number[] } | null>(null);

  // Mostly coming from: https://docs.mapbox.com/mapbox-gl-js/example/animate-a-line/
  useEffect(() => {
    if (!data) {
      setLineData(null);
      setIconData(null);
      return;
    }
    const coordinates: number[][] = data.geometry.coordinates as any;
    setLineData([]);

    let animation = 0; // to store and cancel the animation
    const speedFactor = 15; // number of frames per longitude degree
    const waitTime = 100;
    const waitTimePlane = 10;
    let startTime = 0;
    let progress = 0; // progress = timestamp - startTime

    function animateLine(timestamp: number) {
      progress = timestamp - startTime;
      const step = Math.ceil(progress / speedFactor);
      // restart if it finishes a loop
      let newLineData: number[][];

      if (step > coordinates.length + waitTime) {
        startTime = timestamp;
        newLineData = [];
      } else {
        newLineData = coordinates.slice(0, step);
      }

      setLineData(newLineData);

      if (newLineData.length > 1) {
        const currentPoint = newLineData[newLineData.length - 1];
        const latestPoint = newLineData[newLineData.length - 2];
        const bearing = turf.bearing(latestPoint, currentPoint);

        if (newLineData.length === coordinates.length && step > coordinates.length + waitTimePlane) {
          setIconData(null);
        } else {
          setIconData({
            bearing,
            coordinates: latestPoint,
          });
        }
      }

      // Request the next frame of the animation.
      animation = requestAnimationFrame(animateLine);
    }

    startTime = performance.now();

    animateLine(startTime);

    return () => {
      cancelAnimationFrame(animation);
    };
  }, [data]);

  return {
    lineData: lineData
      ? {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: lineData,
            },
          },
        ],
      }
      : {
        type: "FeatureCollection",
        features: [],
      },
    iconData: iconData
      ? {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              bearing: iconData.bearing,
            },
            geometry: {
              type: "Point",
              coordinates: iconData.coordinates,
            },
          },
        ],
      }
      : {
        type: "FeatureCollection",
        features: [],
      },
  };
}
