export const AABB = {
  collide: function (el1: HTMLElement, el2: HTMLElement) {
    const rect1 = el1.getBoundingClientRect();
    const rect2 = el2.getBoundingClientRect();

    return !(rect1.top > rect2.bottom || rect1.right < rect2.left || rect1.bottom < rect2.top || rect1.left > rect2.right);
  },

  inside: function (el1: HTMLElement, el2: HTMLElement) {
    const rect1 = el1.getBoundingClientRect();
    const rect2 = el2.getBoundingClientRect();

    return (
      rect2.top <= rect1.top &&
      rect1.top <= rect2.bottom &&
      rect2.top <= rect1.bottom &&
      rect1.bottom <= rect2.bottom &&
      rect2.left <= rect1.left &&
      rect1.left <= rect2.right &&
      rect2.left <= rect1.right &&
      rect1.right <= rect2.right
    );
  },
};
