import { orderBy, random } from "lodash";
import { flowResult } from "mobx";
import { observer } from "mobx-react";
import { createContext, PropsWithChildren, useContext, useEffect, useMemo } from "react";
import { useAsync } from "react-async-hook";
import { useSearchParams } from "react-router-dom";

import { useCacheStorage } from "@app/hooks/useCacheStorage";
import { useSavedCity } from "@app/modules/Map/hooks/useSavedCity";
import { City } from "@app/modules/Map/models/City";
import { Flight, FlightClass } from "@app/modules/Map/models/Flight";
import { useCitiesService } from "@app/modules/Map/services/CitiesService";

export interface FlightsExplorerService {
  cabin: FlightClass;
  setCabin: (cabin: FlightClass) => void;
  miles?: number;
  setMiles: (miles?: number) => void;
  originCity?: City;
  setOriginCity: (city: City | undefined) => void;
  destinationCity?: City;
  setDestinationCity: (city: City | undefined) => void;
  flights?: Flight[];
  isFlightsLoading: boolean;
}

export const FlightsExplorerContext = createContext<FlightsExplorerService>(null as never);

function getRandomOriginCity() {
  const randomCities = ["PAR", "AMS"];
  const randomCityIndex = random(0, randomCities.length - 1);
  return randomCities[randomCityIndex];
}

export const FlightsExplorerServiceProvider = observer((props: PropsWithChildren) => {
  const { cities } = useCitiesService();

  const [searchParams, setSearchParams] = useSearchParams();
  const [cabin, setCabin] = useCacheStorage<FlightClass>("class", FlightClass.ECONOMY);
  const [miles, setMiles] = useCacheStorage<number | undefined>("miles", undefined);
  const [originCity, setOriginCity, originCityId] = useSavedCity("origin", undefined);
  const [destinationCity, setDestinationCity] = useSavedCity("destination", undefined);

  const { result: flights, loading } = useAsync<Flight[]>(async () => {
    return new Promise((resolve, reject) => {
      async function fetchFlights() {
        if (!originCity) {
          return [];
        }
        await flowResult(originCity.fetchFlights());
        let flights = originCity.flights || [];
        flights = flights.filter((flight) => flight.cabin === cabin);
        if (miles) {
          return flights.filter((flight) => flight.entryMilesAmount <= +miles);
        }
        return flights;
      }
      requestAnimationFrame(() => {
        fetchFlights().then(resolve).catch(reject);
      });
    });
  }, [originCity, destinationCity, cabin, miles]);

  useEffect(() => {
    if (cities && !originCityId) {
      const currentRegion = navigator.language.split("-")[1] || navigator.language;
      const availableCities = orderBy(
        cities.filter((city) => city.country.toLocaleLowerCase() === currentRegion.toLocaleLowerCase()),
        "countryRank"
      );
      if (availableCities.length) {
        setOriginCity(availableCities[0], false);
      } else {
        const randomCity = cities.find((city) => city.code === getRandomOriginCity());
        setOriginCity(randomCity!, false);
      }
    }
  }, [cities, originCityId]);

  useEffect(() => {
    if (destinationCity) {
      setOriginCity(originCity);
    }
  }, [destinationCity]);

  useEffect(() => {
    if (miles) {
      searchParams.set("miles", JSON.stringify(miles));
    } else {
      searchParams.delete("miles");
    }
    if (originCity) {
      searchParams.set("originCity", JSON.stringify(originCity.id));
    } else {
      searchParams.delete("originCity");
    }
    if (destinationCity) {
      searchParams.set("destinationCity", JSON.stringify(destinationCity.id));
    } else {
      searchParams.delete("destinationCity");
    }
    if (cabin) {
      searchParams.set("cabin", JSON.stringify(cabin));
    } else {
      searchParams.delete("cabin");
    }
    setSearchParams(searchParams, {
      replace: true,
    });
  }, [miles, originCity, destinationCity, cabin]);

  const flightsExplorerService = useMemo(
    () => ({
      cabin,
      setCabin,
      miles,
      setMiles,
      originCity,
      setOriginCity,
      destinationCity,
      setDestinationCity,
      flights,
      isFlightsLoading: loading,
    }),
    [cabin, setCabin, miles, setMiles, originCity, setOriginCity, destinationCity, setDestinationCity, flights]
  );

  return <FlightsExplorerContext.Provider value={flightsExplorerService}>{props.children}</FlightsExplorerContext.Provider>;
});

export const useFlightsExplorerService = () => useContext(FlightsExplorerContext);
