import { Dialog } from "@headlessui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as PenIcon } from "@app/assets/icons/crayon_edit-preferences.svg";
import { ReactComponent as AirportsArrowHorizontal } from "@app/assets/images/FlightsExplorer/cities-book-arrow.svg";
import { ReactComponent as WorldMapImage } from "@app/assets/images/FlightsExplorer/world-map.svg";
import { AnimatedLink } from "@app/modules/Animations/components/AnimatedLink/AnimatedLink";
import { CabinSelector } from "@app/modules/Map/components/CabinSelector/CabinSelector";
import { CitiesSelector } from "@app/modules/Map/components/CitiesSelector/CitiesSelector";
import { FlightsList } from "@app/modules/Map/components/FlightsList/FlightsList";
import { FlightsMap } from "@app/modules/Map/components/FlightsMap/FlightsMap";
import { MilesSelector } from "@app/modules/Map/components/MilesSelector/MilesSelector";
import { CitiesServiceProvider } from "@app/modules/Map/services/CitiesService";
import { FlightsExplorerServiceProvider, useFlightsExplorerService } from "@app/modules/Map/services/FlightsExplorerService";

const FlightsNavigatorWithContext = () => {
  const { t } = useTranslation(["landingpage"]);
  const { cabin, setCabin, originCity } = useFlightsExplorerService();

  // Use states for the fullscreen mobile version
  const [isFiltersOpen, setIsFilterOpen] = useState<boolean>(false);
  const [isFullscreen, setFullscreen] = useState<boolean>(false);

  return (
    <div className="container relative px-5 phablet:px-6 laptop:px-8">
      <MilesSelector />
      <div className="mt-[40px] desktop:mt-[50px] flex flex-col tablet:grid grid-rows-2 tablet:grid-rows-1 tablet:grid-cols-3 gap-[20px] tablet:gap-[42px]">
        <CitiesSelector className="tablet:col-span-2" />
        <CabinSelector value={cabin} onChange={setCabin}></CabinSelector>
      </div>
      <div className="mt-[40px] flex flex-col tablet:grid tablet:grid-cols-2 desktop:grid-cols-3 gap-[42px] tablet:h-[500px] tablet:max-h-[500px]">
        <div className="flex overflow-hidden rounded desktop:col-span-2 h-[200px] tablet:h-full">
          {originCity && <FlightsMap onFullscreen={() => setFullscreen(true)}></FlightsMap>}
        </div>
        <FlightsList />
      </div>
      {/* <div className="flex flex-col items-center justify-between p-4 mt-10 border rounded-lg max-h-96 phablet:flex-row phablet:max-h-34 border-gray">
        <p className="mx-2 mb-5 text-sm text-center phablet:mb-0 phablet:mx-0 phablet:text-left phablet:w-64 tablet:w-72 laptop:w-96 text-blue">
          {t("pdf.body")}
        </p>
        <div className="flex flex-col items-center justify-center phablet:flex-row">
          <WorldMapImage />
          <p className="my-3 text-sm font-bold phablet:my-0 phablet:mx-2 laptop:mx-5 laptop:mr-12 text-blue">{t("pdf.name")}</p>
          <AnimatedLink
            href={t("pdf.link")}
            target="_blank"
            className="px-8 py-3 font-bold rounded-full text-md"
            idleClassName="bg-blue text-white"
            hoverClassName="text-blue bg-white"
            rel="noreferrer"
            text={t("pdf.cta")}
          />
        </div>
      </div> */}
      <p className="mt-5 text-sm text-blue">{t("body.legal")}</p>

      {/* Fullscreen flights navigator on mobile */}
      {isFullscreen && (
        <Dialog open={isFullscreen} onClose={() => setFullscreen(false)}>
          <Dialog.Panel className="fixed top-0 bottom-0 left-0 right-0 z-50">
            <div className="relative flex flex-col h-full">
              <div
                onClick={() => setFullscreen(false)}
                className="z-30 flex flex-row items-center justify-start h-20 px-6 text-white cursor-pointer bg-blue"
              >
                <AirportsArrowHorizontal className="mr-4 text-white rotate-180 stroke-white"></AirportsArrowHorizontal>
                <h4>{t("map.fields.more.flights")}</h4>
              </div>
              {isFiltersOpen ? (
                <div className="absolute z-40 flex flex-col items-center w-screen transition-opacity ease-in-out delay-150 bg-white rounded-b-3xl top-20">
                  <div className="flex flex-col gap-[20px] my-5">
                    <MilesSelector />
                    <CitiesSelector className="tablet:col-span-2" />
                    <CabinSelector value={cabin} onChange={setCabin}></CabinSelector>
                    <button
                      onClick={() => setIsFilterOpen(false)}
                      className="w-full px-3 py-3 mx-auto my-4 font-semibold text-white bg-blue text-md rounded-xl"
                    >
                      {t("map.mobile.fullscreen.closepref")}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="absolute z-30 flex flex-row items-center justify-around w-auto transition-opacity ease-in-out delay-150 bg-white border cursor-pointer border-gray rounded-xl top-28 right-5">
                  <a onClick={() => setIsFilterOpen(true)} className="m-2 text-xs italic text-center font-extralight text-blue">
                    {t("map.mobile.fullscreen.editpref")}
                  </a>
                  <PenIcon className="w-3 h-3 mr-2"></PenIcon>
                </div>
              )}
              {isFiltersOpen && (
                <div className="absolute z-[25] w-screen h-screen bg-black bg-opacity-60" onClick={() => setIsFilterOpen(false)}></div>
              )}

              <div className="flex-grow w-screen rounded">
                <FlightsMap></FlightsMap>
              </div>
              <div className="z-20 pt-5 -mt-5 bg-white rounded-t-3xl">
                <FlightsList horizontal />
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      )}
    </div>
  );
};

export const FlightsNavigator = () => {
  return (
    <CitiesServiceProvider>
      <FlightsExplorerServiceProvider>
        <FlightsNavigatorWithContext></FlightsNavigatorWithContext>
      </FlightsExplorerServiceProvider>
    </CitiesServiceProvider>
  );
};
