import { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useParams } from "react-router-dom";

import ErrorBoundary from "@app/components/ErrorBoundary/ErrorBoundary";
import { Layout } from "@app/components/Layout/Layout";
import { useLocalStorage } from "@app/hooks/useLocalStorage";
import { CookiesConsentModal } from "@app/modules/Cookies/components/CookiesConsentModal/CookiesConsentModal";
import { CookiesContainer } from "@app/modules/Cookies/components/CookiesContainer/CookiesContainer";
import { HomeView } from "@app/views/HomeView";

const GlobalRouter = () => {
  const { i18n } = useTranslation();
  const match = useParams();

  useEffect(() => {
    i18n.changeLanguage(match.lang);
  }, [match.lang]);

  return (
    <Routes>
      <Route path="/" element={<HomeView />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

const LanguageRedirect = () => {
  const { i18n } = useTranslation();
  const [savedLanguage] = useLocalStorage<string | undefined>("language", undefined);
  return <Navigate to={`/${savedLanguage || i18n.resolvedLanguage}`} replace />;
};

export function App() {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    document.querySelector("html")?.setAttribute("dir", i18n.t("mode"));
  }, [i18n.resolvedLanguage]);

  return (
    <ErrorBoundary>
      <HelmetProvider>
        <Helmet>
          <title>{t("meta.title", { ns: "landingpage" })}</title>
          <meta name="description" content={t("meta.description", { ns: "landingpage" })} />
          <meta name="keywords" content={t("meta.keywords", { ns: "landingpage" })} />
          <meta property="og:image" name="twitter:image" content={t("meta.image", { ns: "landingpage" })} />
          <meta itemProp="image" content={t("meta.image", { ns: "landingpage" })} />
          <link rel='icon' type='image/png' sizes='32x32' href='/favicon_32x32.png' />
          <link rel='icon' type='image/png' sizes='16x16' href='/favicon_180x180.png' />
        </Helmet>
        <CookiesContainer>
          <Layout>
            <Routes>
              <Route path="/:lang/*" element={<GlobalRouter />} />
              <Route path="/" element={<LanguageRedirect />} />
            </Routes>
          </Layout>
          <CookiesConsentModal></CookiesConsentModal>
        </CookiesContainer>
      </HelmetProvider>
    </ErrorBoundary>
  );
}
