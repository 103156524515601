import { sortBy } from "lodash";
import { useMemo } from "react";

import { City } from "@app/modules/Map/models/City";

const zoomMatrix = [
  {
    minZoom: 0,
    minDotRank: 0,
    minCardRank: 0,
    overrides: [
      {
        country: "US",
        minDotRank: 5,
        minCardRank: 5,
      },
    ],
  },
  {
    minZoom: 2,
    minDotRank: 0,
    minCardRank: 0,
    overrides: [
      {
        country: "US",
        minDotRank: 10,
        minCardRank: 5,
      },
    ],
  },
  {
    minZoom: 3,
    minDotRank: 0,
    minCardRank: 0,
    overrides: [
      {
        country: "US",
        minDotRank: 15,
        minCardRank: 8,
      },
    ],
  },
  {
    minZoom: 4,
    minDotRank: 5,
    minCardRank: 0,
    overrides: [
      {
        country: "US",
        minDotRank: Infinity,
        minCardRank: 8,
      },
    ],
  },
  {
    minZoom: 5,
    minDotRank: 10,
    minCardRank: 2,
    overrides: [
      {
        country: "US",
        minDotRank: Infinity,
        minCardRank: 5,
      },
    ],
  },
  {
    minZoom: 5.5,
    minDotRank: 25,
    minCardRank: 2,
    overrides: [
      {
        country: "US",
        minDotRank: Infinity,
        minCardRank: 8,
      },
    ],
  },
  {
    minZoom: 6,
    minDotRank: 30,
    minCardRank: 2,
    overrides: [
      {
        country: "US",
        minDotRank: Infinity,
        minCardRank: 10,
      },
    ],
  },
  {
    minZoom: 7,
    minDotRank: Infinity,
    minCardRank: 10,
    overrides: [
      {
        country: "US",
        minDotRank: Infinity,
        minCardRank: 10,
      },
    ],
  },
];

export const getZoomLevel = (zoom: number) => {
  const sortedMatrix = sortBy(zoomMatrix, "minZoom").reverse();
  const rankValue = sortedMatrix.find((value) => zoom >= value.minZoom);
  return rankValue;
};

export const isRankVisible = (rank: number, zoom: number) => {
  const sortedMatrix = sortBy(zoomMatrix, "minZoom").reverse();
  const rankValue = sortedMatrix.find((value) => zoom >= value.minZoom);
  return rankValue ? rank <= rankValue.minDotRank : false;
};

export const isCityVisible = (city: City, zoom: number) => {
  const sortedMatrix = sortBy(zoomMatrix, "minZoom").reverse();
  const rankValue = sortedMatrix.find((value) => zoom >= value.minZoom);
  if (!rankValue) {
    return false;
  }
  const dataSource = rankValue.overrides.find((valuesOverride) => valuesOverride.country === city.country) || rankValue;
  return city.countryRank <= dataSource.minDotRank;
};

export const isCardRankVisible = (rank: number, zoom: number) => {
  const sortedMatrix = sortBy(zoomMatrix, "minZoom").reverse();
  const rankValue = sortedMatrix.find((value) => zoom >= value.minZoom);
  return rankValue ? rank <= rankValue.minCardRank : false;
};

export const useRankVisible = (rank: number, zoom: number) => {
  return useMemo(() => {
    return isRankVisible(rank, zoom);
  }, [rank, zoom]);
};

export const useCityVisible = (city: City, zoom: number) => {
  return useMemo(() => {
    return isCityVisible(city, zoom);
  }, [city, zoom]);
};
