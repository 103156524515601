import { useMemo } from "react";

import { City } from "@app/modules/Map/models/City";
import { FlightClass } from "@app/modules/Map/models/Flight";

export const useEntryMilesAmount = (destinationCity: City, originCity: City, cabin: FlightClass) => {
  return useMemo(() => {
    if (!originCity) {
      return 0;
    }
    const flights = (originCity.flights || []).filter(
      (flight) => flight.destination.cityId === destinationCity.id && flight.cabin === cabin
    );
    return flights.reduce((amount, flight) => (amount === -1 ? flight.entryMilesAmount : Math.min(amount, flight.entryMilesAmount)), -1);
  }, [destinationCity, originCity, cabin]);
};
