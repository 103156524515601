import { sortBy } from "lodash";
import { observer } from "mobx-react";
import { Fragment, useCallback, useMemo } from "react";

import { CityMarker } from "@app/modules/Map/components/FlightsMap/CityMarker/CityMarker";
import { useFilteredCities } from "@app/modules/Map/hooks/useFilteredCities";
import { useVisibleCities } from "@app/modules/Map/hooks/useVisibleCities";
import { City } from "@app/modules/Map/models/City";
import { useFlightsExplorerService } from "@app/modules/Map/services/FlightsExplorerService";

type Props = {
  currentZoom: number;
};

export const CityMarkers = observer((props: Props) => {
  const { originCity, destinationCity, setDestinationCity } = useFlightsExplorerService();
  const { currentZoom } = props;

  const selectCity = useCallback(
    (city: City) => {
      if (destinationCity && destinationCity.id === city.id) {
        setDestinationCity(undefined);
      } else if (originCity && originCity.id !== city.id) {
        setDestinationCity(city);
      }
    },
    [setDestinationCity, destinationCity, originCity]
  );

  const filteredCities = useFilteredCities(currentZoom);

  const scale = useMemo(() => {
    const result = Math.min(100, (4 / (8 - currentZoom)) * 100);
    return result < 0 ? 100 : result;
  }, [currentZoom]);

  const { visibleCities, onRef } = useVisibleCities({
    filteredCities,
    currentZoom,
    destinationCity,
    originCity,
  });

  return (
    <Fragment>
      {filteredCities &&
        sortBy(filteredCities, (city) => visibleCities.includes(city.id)).map((city) => (
          <CityMarker
            key={city.id}
            city={city}
            scale={scale}
            ref={(el) => onRef(city, el)}
            isVisible={visibleCities.includes(city.id)}
            onClick={() => selectCity(city)}
          ></CityMarker>
        ))}
    </Fragment>
  );
});
