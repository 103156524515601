import clsx from "clsx";
import { ComponentPropsWithoutRef, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as PlaneArrivalIcon } from "@app/assets/icons/plane-arrival.svg";
import { ReactComponent as PlaneDepartureIcon } from "@app/assets/icons/plane-departure.svg";
import { ReactComponent as CitiesArrowHorizontal } from "@app/assets/images/FlightsExplorer/cities-arrow-horizontal.svg";
import { CitySelector } from "@app/modules/Map/components/CitySelector/CitySelector";
import { useFlightsExplorerService } from "@app/modules/Map/services/FlightsExplorerService";

export const CitiesSelector = (props: ComponentPropsWithoutRef<"div">) => {
  const { ...rest } = props;
  const { originCity, setOriginCity, destinationCity, setDestinationCity } = useFlightsExplorerService();
  const { t } = useTranslation(["landingpage"]);

  const onSwitch = useCallback(() => {
    if (!destinationCity || !originCity) {
      return;
    }
    setOriginCity(destinationCity);
    setDestinationCity(originCity);
  }, [originCity, setOriginCity, destinationCity, setDestinationCity]);

  return (
    <div {...rest} className={clsx("flex flex-col tablet:flex-row", rest.className)}>
      <div className="flex-grow">
        <CitySelector
          icon={<PlaneDepartureIcon></PlaneDepartureIcon>}
          label="Departure"
          placeholder={t("map.fields.destinations")}
          value={originCity}
          onChange={setOriginCity}
          excluded={destinationCity ? [destinationCity.code] : []}
        ></CitySelector>
      </div>
      <div className="w-[42px] ml-[9px] tablet:ml-0 tablet:w-[80px] flex justify-center items-center my-[-15px] tablet:my-0 z-10">
        <button
          className="rounded-full w-[42px] h-[42px] bg-blue flex items-center justify-center rotate-90 tablet:rotate-0"
          onClick={onSwitch}
          type="button"
        >
          <CitiesArrowHorizontal className="text-white"></CitiesArrowHorizontal>
        </button>
      </div>
      <div className="flex-grow">
        <CitySelector
          icon={<PlaneArrivalIcon></PlaneArrivalIcon>}
          label="Arrival"
          value={destinationCity}
          onChange={setDestinationCity}
          placeholder={t("map.fields.destinations")}
          clearable
          excluded={originCity ? [originCity.code] : []}
        ></CitySelector>
      </div>
    </div>
  );
};
