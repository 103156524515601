import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { CookieConsentProvider, useCookieConsentContext } from "@use-cookie-consent/react";
import React, { useEffect, useRef } from "react";

import { CookiesServiceProvider } from "@app/modules/Cookies/services/CookiesService";

type Props = {
  children: React.ReactNode;
};

const GOOGLE_GTM_ID = window.__RUNTIME_CONFIG__.GOOGLE_GTM_ID;
const GOOGLE_GTM_ENABLE = window.__RUNTIME_CONFIG__.GOOGLE_GTM_ENABLE === "true";

const CookiesContainerWithProvider = (props: Props) => {
  const { children } = props;
  const { consent } = useCookieConsentContext();

  const enableTracking = GOOGLE_GTM_ENABLE && consent.thirdParty ? true : false;
  const trackingWasEnabled = useRef(enableTracking); 

  useEffect(() => {
    if (!enableTracking && trackingWasEnabled.current) {
      location.reload();
    }
    trackingWasEnabled.current = enableTracking;
  }, [consent, enableTracking]);

  return (
    <GTMProvider state={{ id: GOOGLE_GTM_ID, injectScript: enableTracking }}>
      {children}
    </GTMProvider>
  );
};

export const CookiesContainer = (props: Props) => {
  const { children } = props;
  return (
    <CookieConsentProvider>
      <CookiesServiceProvider>
        <CookiesContainerWithProvider>{children}</CookiesContainerWithProvider>
      </CookiesServiceProvider>
    </CookieConsentProvider>
  );
};
