import { useTranslation } from "react-i18next";

import { ReactComponent as ReadMoreBackground } from "@app/assets/icons/ReadMore/read-more-bg.svg";

export const ReadMoreIcon = () => {
  const { t } = useTranslation(["landingpage"]);
  const onClick = () => {
    const element = document.getElementById("map-section");
    element?.scrollIntoView({ behavior: element ? "smooth" : "auto" });
  };
  return (
    <div className="relative">
      <ReadMoreBackground className="mx-auto cursor-pointer phablet:mx-0" onClick={onClick}></ReadMoreBackground>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={127.882}
        height={127.882}
        className="absolute inset-0 mx-auto pointer-events-none phablet:mx-0 animate-scroll"
      >
        <g data-name="Groupe 518">
          <g data-name="Groupe 517" transform="translate(-488.059 -132.059)">
            <path
              id="a"
              fill="none"
              d="M552.3 160.9c19.3 0 34.9 15.6 34.9 34.9s-15.6 34.9-34.9 34.9c-19.3 0-34.9-15.6-34.9-34.9s15.7-34.9 34.9-34.9z"
            />
            <text>
              <textPath xlinkHref="#a">
                <tspan
                  className="text-xs font-medium whitespace-pre"
                  style={{
                    fill: "#0314b1",
                  }}
                >
                  {t("header.cta")}
                </tspan>
              </textPath>
            </text>
          </g>
        </g>
      </svg>
    </div>
  );
};
