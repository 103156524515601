import { flowResult } from "mobx";
import { useMemo } from "react";
import { useAsync } from "react-async-hook";

import { useCitiesService } from "@app/modules/Map/services/CitiesService";

export const useCityById = (cityId: string) => {
  const citiesService = useCitiesService();

  const { result: cities } = useAsync(async () => {
    return await flowResult(citiesService.fetchCities());
  }, [citiesService.fetchCities]);

  return useMemo(() => cities && cities.find((city) => city.id === cityId), [cities, cityId]);
};
