import { Dialog, Transition } from "@headlessui/react";
import { useCookieConsentContext } from "@use-cookie-consent/react";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AnimatedButton } from "@app/modules/Animations/components/AnimatedButton/AnimatedButton";
import { CookieConsentCheckbox } from "@app/modules/Cookies/components/CookieConsentCheckbox/CookieConsentCheckbox";
import { useCookiesService } from "@app/modules/Cookies/services/CookiesService";

export const CookiesConsentModal = () => {
  const { t } = useTranslation(["landingpage"]);
  const { acceptAllCookies, declineAllCookies, consent } = useCookieConsentContext();
  const { isModalOpen, setModalOpen, setCookiesConsentAnswered, cookiesConsentAnswered } = useCookiesService();
  const [acceptTracking, setAcceptTracking] = useState<boolean>(consent.thirdParty === undefined ? true : consent.thirdParty);

  useEffect(() => {
    setAcceptTracking(consent.thirdParty === undefined ? true : consent.thirdParty);
  }, [consent.thirdParty, consent]);

  const onDeclineAllCookies = useCallback(() => {
    declineAllCookies();
    setModalOpen(false);
    setCookiesConsentAnswered(true);
  }, []);

  const onAcceptCookies = useCallback(() => {
    if (acceptTracking) {
      acceptAllCookies();
    } else {
      declineAllCookies();
    }
    setModalOpen(false);
    setCookiesConsentAnswered(true);
  }, [acceptTracking]);

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => cookiesConsentAnswered && setModalOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full text-center phablet:items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 phablet:translate-y-0 phablet:scale-95"
              enterTo="opacity-100 translate-y-0 phablet:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 phablet:scale-100"
              leaveTo="opacity-0 translate-y-4 phablet:translate-y-0 phablet:scale-95"
            >
              <Dialog.Panel className="relative overflow-hidden text-left transition-all transform bg-white rounded-t-lg shadow-xl phablet:rounded-lg phablet:w-full phablet:max-w-2xl">
                <div className="px-8 pt-9">
                  <Dialog.Title as="h3" className="text-2xl font-bold leading-6 text-blue">
                    {t("cookies.modal.title")}
                  </Dialog.Title>
                  <p className="mt-4 text-sm text-[#596781] whitespace-pre-wrap">{t("cookies.modal.description")}</p>
                  <div className="mt-4">
                    <a className="font-medium outline-none text-cyan hover:opacity-70" href={t("cookies.modal.cta.link")} target="_blank" rel="noreferrer">
                      {t("cookies.modal.cta.text")}
                    </a>
                  </div>
                  <fieldset className="mt-4">
                    <legend className="hidden">Cookies</legend>
                    <div className="divide-y divide-gray">
                      <CookieConsentCheckbox
                        id="fonctionnal"
                        text={t("cookies.modal.checkbox.functionnal")}
                        value={true}
                        disabled
                      ></CookieConsentCheckbox>
                      <CookieConsentCheckbox
                        id="marketing"
                        text={t("cookies.modal.checkbox.tracking")}
                        value={acceptTracking}
                        onChange={setAcceptTracking}
                      ></CookieConsentCheckbox>
                    </div>
                  </fieldset>
                </div>
                <div className="flex flex-col justify-end gap-3 px-6 pt-4 pb-6 mt-5 border-t border-gray phablet:flex-row">
                  <AnimatedButton
                    text={t("cookies.modal.buttons.declineAll")}
                    onClick={onDeclineAllCookies}
                    className="p-3 font-bold rounded-full"
                    idleClassName="bg-white ring-1 text-blue ring-gray ring-inset"
                    hoverClassName="bg-blue text-white"
                  ></AnimatedButton>
                  <AnimatedButton
                    text={t("cookies.modal.buttons.confirmSelection")}
                    onClick={onAcceptCookies}
                    className="p-3 font-bold rounded-full"
                    idleClassName="text-white bg-blue"
                    hoverClassName="bg-white text-blue"
                  ></AnimatedButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
