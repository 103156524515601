import { useTranslation } from "react-i18next";

import { AnimatedLink } from "@app/modules/Animations/components/AnimatedLink/AnimatedLink";

export const LearnMore = () => {
  const { t } = useTranslation(["landingpage"]);

  return (
    <section className="bg-blue min-h-[24rem] tablet:min-h-0 phablet:h-72">
      <div className="flex flex-col py-5 phablet:py-0 items-center min-h-[24rem] tablet:min-h-0 justify-around h-full px-5 mx-auto text-white phablet:px-6 laptop:px-8 max-w-7xl tablet:flex-row tablet:justify-between">
        <div className="flex flex-col phablet:w-[80%] phablet:mr-auto tablet:mr-0 tablet:w-[60%] laptop:w-1/2 ">
          <h4 className="text-2xl font-bold tablet:text-2xl laptop:text-3xl">{t("cash.miles.title")}</h4>
          <h6 className="mt-5 font-normal leading-relaxed">{t("cash.miles.body")}</h6>
        </div>
        <AnimatedLink
          href={t("cash.miles.cta.link")}
          target="_blank"
          className="px-8 py-3 font-bold rounded-full text-md"
          idleClassName="bg-white text-blue"
          hoverClassName="text-white bg-blue"
          rel="noreferrer"
          text={t("cash.miles.cta.text")}
        />
      </div>
    </section>
  );
};
