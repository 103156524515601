import { Combobox } from "@headlessui/react";
import clsx from "clsx";
import { flowResult } from "mobx";
import { observer } from "mobx-react";
import { Fragment, ReactElement, useState } from "react";
import { useAsync } from "react-async-hook";
import { useTranslation } from "react-i18next";

import { City } from "@app/modules/Map/models/City";
import { useCitiesService } from "@app/modules/Map/services/CitiesService";

type Props = {
  icon: ReactElement;
  label: string;
  value: City | undefined;
  onChange: (value: City | undefined) => void;
  placeholder?: string;
  clearable?: boolean;
  excluded?: string[]
};

export const CitySelector = observer((props: Props) => {
  const { icon, label, value, onChange, placeholder, clearable = false, excluded = [] } = props;
  const { t } = useTranslation(["locations"]);
  const citiesService = useCitiesService();

  const [query, setQuery] = useState("");

  const { result: options } = useAsync(async () => {
    const cities = await flowResult(citiesService.fetchCities());
    return cities.filter(
      (city) => (t(`cities.${city.code}`).toLowerCase().includes(query.toLowerCase()) || city.code.toLowerCase().includes(query.toLowerCase())) && !excluded.includes(city.code)
    ).slice(0, 10);
  }, [query, citiesService.fetchCities, excluded.join(',')]);

  return (
    <Combobox className="relative" as="div" value={value} onChange={onChange}>
      {({ open }) => (
        <Fragment>
          <Combobox.Label className="hidden">{label}</Combobox.Label>
          <div className="absolute left-[15px] z-10 top-0 bottom-0 flex items-center text-blue">{icon}</div>
          <div className="relative mt-1">
            <Combobox.Button className="relative w-full" as="div">
              <Combobox.Input
                className={clsx(
                  "relative w-full py-[22px] pl-[60px] font-bold text-blue bg-white border border-gray focus:border-blue focus:outline-none focus:ring-1 focus:ring-inset focus:ring-blue phablet:text-sm placeholder:text-left placeholder:font-normal",
                  open ? "rounded-t-md" : "rounded-md"
                )}
                onChange={(event) => setQuery(event.target.value)}
                displayValue={(city: City) => city && t(`cities.${city.code}`)}
                placeholder={placeholder && placeholder}
              />
              { clearable && value && <span
                onClick={(e) => {
                  e.preventDefault();
                  onChange(undefined);
                }}
                title="Clear destination"
                className={clsx(
                  query.length > 0 || value !== undefined ? "flex" : "hidden",
                  "absolute font-bold z-10 top-0 bottom-0 h-6 my-auto cursor-pointer right-4 text-blue"
                )}
              >
                x
              </span>}
            </Combobox.Button>

            {options && options.length > 0 && (
              <Combobox.Options className="absolute z-20 w-full mt-0 overflow-auto text-base bg-white border-b-2 border-blue divide-y-[1px] shadow-lg divide-gray border-x-2 rounded-b-md max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none phablet:text-sm">
                {options.map((city) => (
                  <Combobox.Option
                    key={city.id}
                    value={city}
                    className={({ active }) =>
                      clsx("relative cursor-default select-none py-2 pl-3 pr-9", active ? "bg-blue text-white" : "text-gray-900")
                    }
                  >
                    {({ selected }) => (
                      <>
                        <span className={clsx("block truncate", selected && "font-semibold")}>{t(`cities.${city.code}`)}</span>
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            )}
          </div>
        </Fragment>
      )}
    </Combobox>
  );
});
