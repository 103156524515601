import { flowResult } from "mobx";
import { useCallback, useMemo } from "react";
import { useAsync } from "react-async-hook";

import { useCacheStorage } from "@app/hooks/useCacheStorage";
import { City } from "@app/modules/Map/models/City";
import { useCitiesService } from "@app/modules/Map/services/CitiesService";

//Custom hook to get the City Object from the store based on localStorage hook
export const useSavedCity = (
  key: string,
  defaultValue: string | undefined
): [City | undefined, (city: City | undefined, save?: boolean) => void, string | undefined, (value: string | undefined) => void] => {
  const [cityId, setCityId] = useCacheStorage<string | undefined>(key, defaultValue);

  const citiesService = useCitiesService();

  //Fetch the cities from store
  const { result: cities } = useAsync(async () => {
    return await flowResult(citiesService.fetchCities());
  }, [citiesService.fetchCities]);

  const setCity = useCallback(
    (city: City | undefined, save = true) => {
      setCityId(city?.id, save);
    },
    [cityId, cities]
  );

  const selectedCity = useMemo(() => {
    return cities && cityId ? cities.find((city) => city.id === cityId) : undefined;
  }, [cities, cityId]);

  return [selectedCity, setCity, cityId, setCityId];
};
