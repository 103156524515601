import { flow, makeAutoObservable } from "mobx";
import { createContext, PropsWithChildren, useContext, useMemo } from "react";

import { City } from "@app/modules/Map/models/City";

export class CitiesService {
  cities?: City[];

  constructor() {
    makeAutoObservable(this, {
      fetchCities: flow,
    });
  }

  *fetchCities() {
    if (this.cities) {
      return this.cities;
    }
    const citiesData: any[] = yield fetch("/api/cities.json").then((result) => result.json());
    this.cities = citiesData.map((cityData) => City.createCity(cityData));
    return this.cities;
  }

  getCity(cityId: string) {
    return this.cities ? this.cities.find((city) => city.id === cityId) : undefined;
  }
}

export const CitiesContext = createContext<CitiesService>(null as never);

export const CitiesServiceProvider = (props: PropsWithChildren) => {
  const citiesService = useMemo(() => new CitiesService(), []);
  return <CitiesContext.Provider value={citiesService}>{props.children}</CitiesContext.Provider>;
};

export const useCitiesService = () => useContext(CitiesContext);
