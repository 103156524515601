import { makeAutoObservable } from "mobx";

import { City } from "@app/modules/Map/models/City";

export enum FlightClass {
  ECONOMY = "Economy",
  BUSINESS = "Business",
  PREMIUM_ECONOMY = "Premium Economy",
}

export interface FlightLocation {
  cityId: string;
}

export class Flight {
  id!: string;
  origin!: FlightLocation;
  destination!: FlightLocation;
  cabin!: FlightClass;
  entryMilesAmount!: number;
  city!: City;

  static createFlight(city: City, data: Flight): Flight {
    const flight = new Flight();
    Object.assign(flight, data);
    flight.city = city;
    return flight;
  }

  private constructor() {
    makeAutoObservable(this);
  }
}
