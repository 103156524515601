import clsx from 'clsx';

type Props = {
  id: string;
  value: boolean;
  onChange?: (value: boolean) => void;
  text: string;
  disabled?: boolean;
};

export const CookieConsentCheckbox = (props: Props) => {
  const { id, text, onChange, value, disabled = false} = props;
  return (
    <div>
      <input
        id={`CookieConsentCheckbox-${id}`}
        type="checkbox"
        checked={value}
        className="absolute invisible"
        disabled={disabled}
        onChange={(e) => onChange && onChange(e.target.checked)}
      />
      <label htmlFor={`CookieConsentCheckbox-${id}`} className={clsx("flex py-4", disabled ? "cursor-not-allowed": "cursor-pointer")}>
        <div className={clsx("box-border flex items-center justify-center w-5 h-5 border-2 rounded-full", disabled ? "border-blue-light" : "border-blue")}>
          {value && <div className={clsx("w-[0.65rem] h-[0.65rem] rounded-full", disabled ? "bg-blue-light" : "bg-blue")}></div>}
        </div>
        <span className={clsx("ml-2 text-sm font-bold select-none", disabled ? "text-blue-light" : "text-blue")}>{text}</span>
      </label>
    </div>
  );
};
