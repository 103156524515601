import { uniqBy } from "lodash";
import { useMemo } from "react";

import { useDebounce } from "@app/hooks/useDebounce";
import { isCityVisible } from "@app/modules/Map/hooks/useRankVisible";
import { City } from "@app/modules/Map/models/City";
import { useCitiesService } from "@app/modules/Map/services/CitiesService";
import { useFlightsExplorerService } from "@app/modules/Map/services/FlightsExplorerService";

export const useFilteredCities = (currentZoom: number) => {
  const { originCity, destinationCity } = useFlightsExplorerService();
  const citiesService = useCitiesService();
  const { flights } = useFlightsExplorerService();

  const filteredCities = useMemo(() => {
    if (!flights) {
      return;
    }
    const cities = flights
      .reduce((cities, flight) => {
        const city = citiesService.getCity(flight.destination.cityId)!;
        if (!cities.includes(city)) {
          return cities.concat([city]);
        }
        return cities;
      }, [] as City[])
      .filter((city) => !!city);

    return uniqBy(
      cities
        .filter((city) => {
          if (city.location.latitude === 0) {
            return false;
          }
          if (originCity?.id === city.id || destinationCity?.id === city.id) {
            return true;
          }
          if (!isCityVisible(city, currentZoom)) {
            return false;
          }

          const flights = (originCity?.flights || []).filter((flight) => flight.destination.cityId === city.id);
          return (
            flights.reduce((amount, flight) => (amount === -1 ? flight.entryMilesAmount : Math.min(amount, flight.entryMilesAmount)), -1) >
            0
          );
        })
        .concat([originCity, destinationCity].filter((city) => !!city) as City[]),
      "id"
    );
  }, useDebounce([flights, originCity, destinationCity, currentZoom], 300));

  return filteredCities;
};
